import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "menu-panel" }
const _hoisted_2 = { class: "popup-main" }
const _hoisted_3 = { class: "popup-header flex justify-between" }
const _hoisted_4 = { class: "popup-content" }
const _hoisted_5 = { class: "profile-wrap" }
const _hoisted_6 = { class: "balance-wrap" }
const _hoisted_7 = { class: "balance-value" }
const _hoisted_8 = { class: "balance-wrap pointer" }
const _hoisted_9 = { class: "balance-value" }
const _hoisted_10 = {
  key: 0,
  class: "game-menu-list"
}
const _hoisted_11 = ["onClick"]
const _hoisted_12 = { class: "menu-name" }
const _hoisted_13 = { class: "menu-list" }
const _hoisted_14 = ["onClick"]
const _hoisted_15 = { class: "menu-name" }
const _hoisted_16 = {
  key: 0,
  class: "badge-wrap"
}
const _hoisted_17 = {
  key: 1,
  class: "badge-wrap"
}
const _hoisted_18 = {
  key: 2,
  class: "badge-wrap"
}
const _hoisted_19 = {
  key: 0,
  class: "menu-download-wrap flex"
}
const _hoisted_20 = { class: "download-content" }
const _hoisted_21 = { class: "download-text" }
const _hoisted_22 = { class: "popup-footer" }
const _hoisted_23 = { class: "follow-wrap" }
const _hoisted_24 = { class: "flex justify-center" }
const _hoisted_25 = { class: "follow-label" }

import { ref, reactive, inject } from 'vue';
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia';
import { useHelpTool, usePWA } from '@/hooks';
import { useUserStore, useAccountStore, useGameStore, useReddotStore, useSettingsStore } from "@/store";
import packageJson from '/package.json';


export default {
  __name: 'MenuPanel',
  props: ['show'],
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {



const emit = __emit

const MyDialog = inject('myDialog');

const { t } = useI18n()
const router = useRouter()
const { doFollow, openBrowser } = useHelpTool()

const userStore = useUserStore();
const accountStore = useAccountStore();
const { balance, point } = storeToRefs(accountStore);
const gameStore = useGameStore();
const { gameMenu, activeGameMenu } = storeToRefs(gameStore);
const redDotStore = useReddotStore();
const { loginBonusRewardNum, inviteFriendToEarnNum, inboxUnread, taskRewardNum } = storeToRefs(redDotStore);
const settingsStore = useSettingsStore()
const { downloadPage, showDownloadBanner, version } = storeToRefs(settingsStore);
const h5Version = ref(packageJson.version);

const menuList = reactive([
  { code: 'records',  pathName: 'records' },
  { code: 'gameRecords',  pathName: 'gameRecord' },
  // { code: 'chat',  pathName: 'livechat' },
  { code: 'inbox',  pathName: 'inbox' },
  { code: 'events',  pathName: 'event' },
  // { code: 'security',  pathName: 'securityCenter' },
  // { code: 'settings',  pathName: 'setting' },
  { code: 'redeem',  pathName: 'redeem' },
]);

const socialMediaList = ref(['facebook', 'telegram']);

const clickGameMenu = item => {
  if (router.currentRoute.value.name === 'game') {
    gameStore.changeMenu(item);
    emit('onClose');
  } else {
    router.push({ name: 'game' })
  }
} 

const clickMenu = item => {
  if(item.onClick) {
    item.onClick()
  } else {
    router.push({ name: item.pathName })
  }
}

const doLogout = () => {
  MyDialog.confirm({
    message: t('msg.logout'),
  })
  .then(() => {// on confirm
    userStore.logout();
  })
  .catch(() => {});
}

const reloadApp = () => {
  location.href = `${location.origin}?t=${new Date().getTime()}`
}

return (_ctx, _cache) => {
  const _component_van_image = _resolveComponent("van-image")
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: __props.show,
      position: "right",
      "close-on-click-overlay": false,
      onClickOverlay: _cache[6] || (_cache[6] = $event => (emit('onClose')))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _withDirectives(_createVNode(_component_van_image, {
              class: "popup-title",
              src: require('@/assets/img/common/menu-title.png'),
              onClick: _cache[0] || (_cache[0] = $event => (_ctx.$router.push({ name: 'game' })))
            }, null, 8, ["src"]), [
              [_directive_audio]
            ]),
            _withDirectives(_createVNode(_component_van_icon, {
              class: "close-icon",
              name: "close",
              onClick: _cache[1] || (_cache[1] = $event => (emit('onClose')))
            }, null, 512), [
              [_directive_audio, void 0, "close"]
            ])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", {
                class: "profile-title flex",
                onClick: _cache[2] || (_cache[2] = $event => (_ctx.$router.push({ name: 'profile' })))
              }, [
                _createVNode(_component_van_image, {
                  class: "profile-img",
                  src: require('@/assets/img/common/profile.png')
                }, null, 8, ["src"]),
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('user.myprofile').toUpperCase()), 1)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_van_image, {
                  class: "coin-icon",
                  src: require('@/assets/img/common/coin.png')
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$filter.outputAmount(_unref(balance))), 1),
                _withDirectives(_createVNode(_component_van_image, {
                  class: "action-icon",
                  src: require('@/assets/img/common/add.png'),
                  onClick: _cache[3] || (_cache[3] = $event => (_ctx.$router.push({ name: 'recharge' })))
                }, null, 8, ["src"]), [
                  [_directive_audio]
                ])
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_van_image, {
                  class: "pointer-icon",
                  src: require('@/assets/img/common/pointer.png')
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$filter.outputAmount(_unref(point))), 1),
                _withDirectives(_createVNode(_component_van_image, {
                  class: "action-icon",
                  src: require('@/assets/img/common/exchange.png'),
                  onClick: _cache[4] || (_cache[4] = $event => (_ctx.$router.push({ name: 'exchange' })))
                }, null, 8, ["src"]), [
                  [_directive_audio]
                ])
              ])
            ]),
            (_unref(gameMenu) && _unref(gameMenu).length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(gameMenu), (item) => {
                    return _withDirectives((_openBlock(), _createElementBlock("div", {
                      class: _normalizeClass(`menu-item flex ${ _unref(router).currentRoute.value.name === 'game' && _unref(activeGameMenu) && _unref(activeGameMenu).code === item.code ? 'active' : 'inactive'}`),
                      key: item.code,
                      onClick: $event => (clickGameMenu(item))
                    }, [
                      _createElementVNode("div", _hoisted_12, _toDisplayString(item.name) + " GAMES", 1),
                      _createVNode(_component_van_image, {
                        class: "menu-icon",
                        fit: "contain",
                        src: require(`@/assets/img/common/menu/${item.code}.png`)
                      }, null, 8, ["src"]),
                      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "divider-line" }, null, -1))
                    ], 10, _hoisted_11)), [
                      [_directive_audio]
                    ])
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_13, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(menuList, (item) => {
                return _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: _normalizeClass(`menu-item flex ${_ctx.$route.name === item.pathName ? 'active' : 'inactive'}`),
                  key: item.code,
                  onClick: $event => (clickMenu(item))
                }, [
                  _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t(`menu.${item.code}`).toUpperCase()), 1),
                  (item.code === 'inbox' && _unref(inboxUnread))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(_unref(inboxUnread)), 1))
                    : (item.code === 'signUpBonus' && _unref(loginBonusRewardNum))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _toDisplayString(_unref(loginBonusRewardNum)), 1))
                      : (item.code === 'task' && _unref(taskRewardNum))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_18, _toDisplayString(_unref(taskRewardNum)), 1))
                        : _createCommentVNode("", true),
                  _createVNode(_component_van_image, {
                    class: "menu-icon",
                    fit: "contain",
                    src: require(`@/assets/img/common/menu/${item.code}.png`)
                  }, null, 8, ["src"]),
                  _cache[8] || (_cache[8] = _createElementVNode("div", { class: "divider-line" }, null, -1))
                ], 10, _hoisted_14)), [
                  [_directive_audio]
                ])
              }), 128))
            ])
          ]),
          (_unref(showDownloadBanner))
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createVNode(_component_van_image, {
                  class: "logo-img",
                  src: require('@/assets/img/common/logo.png')
                }, null, 8, ["src"]),
                _createElementVNode("div", _hoisted_20, [
                  _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.$t('download.content')), 1),
                  _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: "download-btn",
                    onClick: _cache[5] || (_cache[5] = $event => (_unref(openBrowser)(_unref(downloadPage))))
                  }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.downloadNow').toUpperCase()) + "!", 1)
                  ])), [
                    [_directive_audio]
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(socialMediaList.value, (item) => {
                  return _withDirectives((_openBlock(), _createBlock(_component_van_image, {
                    class: "social-media-icon",
                    fit: "contain",
                    src: require(`@/assets/img/common/socialMedia/icon/${item}.png`),
                    onClick: $event => (_unref(doFollow)({ type: item }))
                  }, null, 8, ["src", "onClick"])), [
                    [_directive_audio]
                  ])
                }), 256))
              ]),
              _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.$t('socialMedia.followShare')), 1)
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "logout-btn",
              onClick: doLogout
            }, [
              _createTextVNode(_toDisplayString(_ctx.$t('common.logout').toUpperCase()), 1)
            ])), [
              [_directive_audio]
            ]),
            _createElementVNode("div", {
              class: "app-version",
              onClick: reloadApp
            }, "V" + _toDisplayString(h5Version.value) + _toDisplayString(_unref(version) ? ` (${_unref(version)})` : ''), 1)
          ])
        ])
      ]),
      _: 1
    }, 8, ["show"])
  ]))
}
}

}