import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, unref as _unref, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "in-app-frame" }
const _hoisted_2 = { class: "frame-content flex" }
const _hoisted_3 = { class: "flex-1 flex justify-end" }
const _hoisted_4 = { class: "frame-main" }
const _hoisted_5 = ["src"]

import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { usePopupStore } from '@/store'


export default {
  __name: 'InAppFrame',
  emits: ['onClose'],
  setup(__props, { emit: __emit }) {

const emit = __emit

const popupStore = usePopupStore();
const { frameUrl, frameTitle } = storeToRefs(popupStore);

const isHideHeader = ref(false)

onMounted(() => {
  setTimeout(() => {
    isHideHeader.value = true 
  }, 1000)
})

return (_ctx, _cache) => {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_popup = _resolveComponent("van-popup")
  const _directive_audio = _resolveDirective("audio")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_van_popup, {
      show: true,
      "close-on-click-overlay": false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass(["frame-header", { 'hide': isHideHeader.value }])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: "close-btn",
              onClick: _cache[0] || (_cache[0] = $event => (emit('onClose')))
            }, [
              _createVNode(_component_van_icon, {
                name: "arrow-left",
                class: "close-icon"
              })
            ])), [
              [_directive_audio, void 0, "close"]
            ]),
            _createElementVNode("div", _hoisted_3, [
              _withDirectives((_openBlock(), _createElementBlock("div", {
                class: "deposit-btn flex",
                onClick: _cache[1] || (_cache[1] = $event => (emit('onClose'), _ctx.$router.push({ name: 'recharge' })))
              }, [
                _createElementVNode("div", null, _toDisplayString(_ctx.$t('common.deposit')), 1)
              ])), [
                [_directive_audio]
              ]),
              _createElementVNode("div", {
                class: "full-btn",
                onClick: _cache[2] || (_cache[2] = $event => (isHideHeader.value = true))
              }, _toDisplayString(_ctx.$t('common.fullScreen')), 1)
            ])
          ]),
          (isHideHeader.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "hide-wrap",
                onClick: _cache[3] || (_cache[3] = $event => (isHideHeader.value = false))
              }, [
                _createVNode(_component_van_icon, {
                  name: "arrow-down",
                  class: "arrow-icon"
                })
              ]))
            : _createCommentVNode("", true)
        ], 2),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("iframe", {
            src: _unref(frameUrl),
            width: "100%",
            height: "100%",
            style: {"border":"none"}
          }, null, 8, _hoisted_5)
        ])
      ]),
      _: 1
    })
  ]))
}
}

}