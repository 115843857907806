import { defineStore } from 'pinia'

export const usePopupStore = defineStore('popup', {
  state: () => {
    return {
      rewards: [],
      rewardInfo: '',
      frameUrl: '',
      frameTitle: '',
      frameCloseFn: undefined,
      walletAccount: null,
      walletCloseFn: undefined,
      showReward: false,
      showFollow: false,
      showAgeVerifiction: false,
      showDocument: false,
      showInAppFrame: false,
      showBindWallet: false,
    }
  },
  actions: {
    set(key, value = true) {
      this[key] = value;
    },
    showRewards(rewards, rewardInfo) {
      if(Array.isArray(rewards)) {
        this.rewards = rewards;
      } else {
        this.rewards = [{ type: "cash", amount: rewards }];
      }
      this.rewardInfo = rewardInfo;
      this.showReward = true;
    },
    openUrlInFrame(url, title = '', closeCallback) {
      this.frameUrl = url;
      this.frameTitle = title;
      this.frameCloseFn = closeCallback || undefined;
      this.showInAppFrame = true;
    },
    closeUrlInFrame() {
      this.frameUrl = '';
      this.frameTitle = '';
      if (this.frameCloseFn) {
        this.frameCloseFn()
      }
      this.frameCloseFn = undefined;
      this.showInAppFrame = false;
    },
    openBindWallet(account, closeCallback) {
      this.walletAccount = account || null;
      this.walletCloseFn = closeCallback || undefined;
      this.showBindWallet = true;
    },
    closeBindWallet() {
      this.walletAccount = null;
      if (this.walletCloseFn) {
        this.walletCloseFn()
      }
      this.walletCloseFn = undefined;
      this.showBindWallet = false;
    },
  }
})
