import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "document-title" }

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('ageVerification.privacyPolicy')), 1),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "document-content" }, [
      _createElementVNode("p", null, "Privacy Policy"),
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, "11111"),
        _createElementVNode("li", null, "222222"),
        _createElementVNode("li", null, "33333")
      ]),
      _createElementVNode("p", null, "Privacy Policy footer")
    ], -1))
  ], 64))
}