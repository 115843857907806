import { router } from '@/router';
import i18n from '@/locales/index';
import MyDialog from '@/plugins/component/MyDialog';
import MyToast from '@/plugins/component/MyToast';
import useClipboard from 'vue-clipboard3';
import { useUserStore, usePopupStore, useAccountStore, useSettingsStore } from '@/store';
import { shareSocialMedia, followSocialMedia, enterGame } from "@/api";

const { toClipboard } = useClipboard();

export const useHelpTool = () => {
  const showLoginDialog = () => {
    MyDialog.confirm({
      message: i18n.global.t('notify.loginFirst'),
    })
    .then(() => {// on confirm
      router.push({ name: 'login' })
    })
    .catch(() => {});
  }

  const checkUserLogin = (callback) => {
    if (!useUserStore().uid) {
      showLoginDialog();
    } else {
      callback && callback();
    }
  }

  const doShare = (postData) => {
    checkUserLogin(()=> {
      shareSocialMedia(postData).then(res => {
        let url = res.data;
        if (window.$bridge) {
          window.$bridge.callHandler('share', JSON.stringify({
            method: 'app',
            url,
            type: 'url'
          }))
        } else {
          MyDialog.alert({ 
            message: i18n.global.t('notify.shareSuccess'),
            type: 'success'
          }).then(() => {// on confirm
            copy(url, false);
          });
        }
      }) 
    })
  }

  const doFollow = (postData) => {
    followSocialMedia(postData).then(res => {
      let url = res.data;
      openBrowser(url, { showAlert: true });
    })
  }

  const doEnterGame = (postData, options={}) => {
    checkUserLogin(()=> {
      enterGame(postData).then(res => {
        let { playUrl, openMode, needBindWallet } = res.data || {};
        if (needBindWallet) {
          usePopupStore().openBindWallet();
        } else {
          if (window.$bridge && openMode === 'browser') {
            window.$bridge.callHandler('openBrowser', playUrl);
          } else {
            usePopupStore().openUrlInFrame(playUrl, options.title || 'GAME', () =>{
              useAccountStore().fetchBalance();
            });
          }
        }
      });
    })
  }

  const copy = async(text, showToast = true) => {
    try {
      await toClipboard(text);
      if(showToast) {
        MyToast(i18n.global.t('notify.copySuccess'));
      }
    } catch (e) {
      console.error(e)
    }
  }

  const openUrl = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openUrl', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openBrowser = (url, options) => {
    if (window.$bridge) {
      window.$bridge.callHandler('openBrowser', url);
    } else {
      openUrlInWeb(url, options);
    }
  }

  const openUrlInWeb = (url, options = {}) => {
    if (options.showAlert || options.message) {
      MyDialog.alert({
        message: options.message ? options.message : i18n.global.t('msg.openBrowser'),
        confirmButtonText: i18n.global.t('common.go').toUpperCase(),
        type: options.alertType
      })
      .then(() => {// on close
        let a = document.createElement('a');
        a.setAttribute('target','_blank');
        a.setAttribute('href', url);
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
    } else {
      let a = document.createElement('a');
      a.setAttribute('target','_blank');
      a.setAttribute('href', url);
      document.body.appendChild(a);
      a.click();
      a.remove();
    }
  }

  // const imageByTheme = (image) => {
  //   return require(`@/assets/img/theme/${useSettingsStore().theme}/${image}`);
  // }

  // const backgroundByTheme = (image) => {
  //   return 'background-image: url(' + imageByTheme(image) + ');';
  // }

  const fade = (color, amount) => {
    // 如果传入的是RGB格式，转换为十六进制
    if (color.startsWith('rgb(')) {
      let match = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
      if (match) {
          let [r, g, b, a] = match.slice(1, 5).map(Number);
          // 如果有alpha值，则使用它，否则默认为1（不透明）
          a = a || 1;
          // 转换为十六进制格式
          color = '#' + ('00000' + (Math.floor(a * 255) | 16).toString(16)).slice(-6);
      }
    }
 
    // 将颜色转换为RGBA对象
    let rgba = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(color);
    if (rgba) {
        // 提取RGBA值
        let r = parseInt(rgba[1], 16);
        let g = parseInt(rgba[2], 16);
        let b = parseInt(rgba[3], 16);
        let a = 1;
        if (amount.indexOf('%' > 0)) {
          a = amount.replace('%', '') / 100;
        }
        // 返回透明度
        return `rgba(${r}, ${g}, ${b}, ${a})`;
    }
    // 如果颜色格式不正确，返回undefined
    return undefined;
  }

  return { 
    showLoginDialog, 
    checkUserLogin, 
    doShare, 
    doFollow, 
    doEnterGame, 
    copy,
    openUrl, 
    openBrowser, 
    // imageByTheme, 
    // backgroundByTheme,
    fade
  }
}
